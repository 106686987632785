<template>
    <div v-if="getAppLoader" class="main main_loader">
        <Loader class="main_loader_ch" />
    </div>
    <div v-else-if="!appLogout">
        <div class="form login">
            <div class="container">
                <h1 class="title">Sign in</h1>
                <form ref="ruleForm">
                    <div class="input__box">
                        <div class="label">Email</div>
                        <input class="input" v-model="email" type="email" placeholder="Enter your email" />
                    </div>
                    <div class="input__box">
                        <div class="label">Password</div>
                        <input class="input" v-model="password" :type="isShowPassword ? 'text' : 'password'"
                            placeholder="Enter your password" />
                        <TogglePassword :isshow="isShowPassword" :toggle="togglePassword" />
                    </div>
                    <div class="error_message" v-if="password_message">
                        <img :src="require('@/assets/static/images/error-message.svg')" alt="Logo">
                        <span>
                            {{ password_message }}
                        </span>
                    </div>
                    <div class="form_footer">
                        <button @click.prevent="submitForm('ruleForm')" :disabled="status === 'pending'"
                            class="auth-btn"><span>Sign in with email</span>
                            <div class="auth-pending-loader" v-if="status === 'pending'"></div>
                        </button>
                        <PrivacyPolicy />
                        <p class="to-signup">
                            Don't have any account? <router-link to="/registration"> Sign up </router-link>
                        </p>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div v-else class="block--white"></div>
</template>

<script>

import { mapMutations, mapActions, mapGetters } from "vuex"
import router from "@/router"
import PrivacyPolicy from "../components/auth/PrivacyPolicy.vue"
import TogglePassword from "../components/auth/TogglePassword.vue";

import { FCM } from '@capacitor-community/fcm'

export default {
    props: {
        span: [Number]
    },
    data() {
        return {
            appLogout: false,
            email: '',
            password: '',
            password_message: '',
            status: '',
            isShowPassword: false,
        }
    },
    mounted() {

        const URLParams = new URLSearchParams(window.location.search)



        // do not load page if there is get param app_logout
        if (URLParams.get('app_logout')) {
            this.appLogout = true
        }

        // check if it is an app auth via web we should save flag to local storage
        // it needs to redirect user to the open app page after google sign in
        if (URLParams.get('app')) {
            localStorage.setItem('app_web_auth', 1)
        }
        else {
            localStorage.removeItem('app_web_auth', 1)
        }

        if (URLParams.get('app_token1') && URLParams.get('app_token2')) {
            localStorage.removeItem('app_web_auth')
            this.electronAppAuth(URLParams.get('app_token1'), URLParams.get('app_token2'))
        }
        else {
            // удаляю w_token и w_clone_token
            localStorage.removeItem("w_token")
            localStorage.removeItem("w_clone_token")

            if (this.$router.history.current.query.message) {

                // если внутри ссылки есть get параметр login -
                // это значит что пользователь попробовал зайти не авторизовавшись
                // выдаю сообщение об ошибке и просьбой авторизоваться

                const routerQuery = {
                    key_message: this.$router.history.current.query.message,
                    message: ""
                }

                this.setMessage(routerQuery)

            }
        }


        // already_sent_firebase_key нужен для повторного запроса на подключения сокета
        localStorage.setItem("already_sent_firebase_key", false)

        if (Notification.permission == 'default' && !window.isElectron) {
            Notification.requestPermission()
        }

    },
    methods: {
        ...mapActions([
            "fetchKey"
        ]),
        ...mapMutations([
            "setMessage",
            "onAppLoader",
            "offAppLoader"
        ]),
        async submitForm() {
            this.password_message = ''
            if (!this.email) {
                this.password_message = "Enter email"
            } else if (!this.password) {
                this.password_message = "Enter password"
            } else if (!String(this.email)
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                )) {
                this.password_message = "Enter your email correctly"
            } else {
                this.status = 'pending';
                let firebaseToken = "";
                try {
                    firebaseToken = await FCM.getToken();
                } catch (e) {
                    alert("Something went wrong while generating firebase token");
                }
                const res = {
                    url: this.$w_api.SIGN_IN,
                    body: {
                        email: this.email,
                        password: btoa(this.password),
                        token: firebaseToken.token
                    }
                }

                // включается прелоадер и закрывается форма
                // отправка данных на сервер
                // fetchKey это action в store/index.js
                const req = await this.fetchKey(res)
                if (req) {
                    this.status = 'success';
                    // если ок, обнуляю форму
                    this.email = ""
                    this.password = ""

                    // Засовываю токен в конфиг сокета
                    this.$socket.io.opts.transportOptions.polling.extraHeaders.Authorization = localStorage.getItem("w_token")

                    // подключание сокета
                    this.$socket.connect()
                    window.location.href = '/'

                } else {
                    // если сервер отклонил снова показываю форму и отключаю прелоадер
                    this.offAppLoader();
                    this.status = 'error'
                }
            }
        },
        togglePassword() {
            this.isShowPassword = !this.isShowPassword;
        },
        electronAppAuth(token, token_clone) {
            localStorage.setItem("w_token", token)
            localStorage.setItem("w_clone_token", token_clone)

            setTimeout(() => {
                router.push('/')
            }, 200)
        }
    },
    computed: {
        ...mapGetters(["getAppLoader"])
    },
    components: {
        PrivacyPolicy,
        TogglePassword
    }
}

</script>
